<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
        h1.my-0 {{$t('GPT_MENU.KNOWLEDGE')}}
        v-btn.ml-3.mb-1(@click='showAddDialog' width='36' height='36' :ripple='false' color='success' depressed dark fab)
          v-icon(size='16') icon-add
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
      .data-area.knowledge_height.scrollbar(v-if='!loading')
          v-container.upgpt-container(max-content)
           v-row.knowledges-row.d-flex.align-center.justify-start.pl-4.pt-4
            v-col(class="mt-1" cols="auto" v-for="(data,index) in Knowledges_list" :key='data.id').pl-0.pt-0
              v-hover
                template(v-slot:default="{ hover }")
                  v-card.rounded-card.knowledges-card-width(:class="hover ? 'knowledges-card-hover' : ''")
                        v-card-title.pa-0.pt-1.d-flex.align-center.justify-space-between
                          div.pa-2.d-flex.align-center(width="50px")
                            v-img.ml-2(id='rounded-card' src="../assets/images/book1.png" class="white--text align-end"  width="50px")
                          div.mr-3
                            v-avatar.ml-1.knowledges-avatar-28(v-for='item,index in data.robots.slice(0, 5)' :key='index' color="#e9f3f8" :title='item.bot_name')
                              span {{ item.bot_name[0] }}
                        v-card-title.pa-0.d-flex.align-center
                          div.pl-1(width="50px")
                            h4(:title='data.name').ellipsis.pl-3 {{ data.name }}
                        v-card-text.mt-0.pb-0
                          div.knowledges-card-description
                            p.multiline-two-ellipsis.t-black(:title='data.description') {{data.description}}
                        v-divider.ma-0.mt-3.ml-3.mr-3
                        v-card-text(@click='').pb-0.knowledges-card-text
                          p.mb-2.span.t-black(:title='data.robots_count') {{$t("GPT_KNOWLEDGE.ROBOTS")}} : 
                            span {{data.robots_count}}
                          p.mb-4.span.t-black(:title='data.docs_count') {{$t("GPT_KNOWLEDGE.DOCS")}} : 
                            span {{data.docs_count}}
                          //- p.mb-2.span(:title='getFormatTime(data.created_on)') {{$t("GPT_KNOWLEDGE.CREATED_ON")}} : 
                          //-   span.span {{getFormatTime(data.created_on)}}
                          //- p.mb-1.span(:title='getFormatTime(data.updated_on)') {{$t("GPT_KNOWLEDGE.UPDATED_ON")}} : 
                          //-   span.span {{getFormatTime(data.updated_on)}}
                          p.mb-2.span(:title='data.updated_by.last_name+""+data.updated_by.first_name') {{$t("GPT_KNOWLEDGE.UPDATED_BY")}} : 
                            v-avatar.knowledges-avatar-28(color="aliceBlue" :title='data.updated_by.last_name+""+data.updated_by.first_name')
                              span.span(v-if="data.updated_by.usersetting.photo === ''") {{ data.updated_by.last_name }}
                              img(v-else :src="data.updated_by.usersetting.photo" :alt='data.updated_by.usersetting.last_name')
                          
                          div.d-flex.align-center.justify-space-between.pb-3
                            v-btn(:href='"/upgpt/Knowledges/"+data.id' width='50%' height='30' color='#18bedf' :ripple='false'  depressed  dark style='border-radius:50px')
                                span {{$t("GENERAL.EDIT")}}
                            div
                              v-btn(fab :icon='!data.active' :color='data.active? "#5fe98b":"#6c757d"' @click='openKnowledge(data)' width='24' height='24' :ripple='false'  depressed dark :title='$t("GPT_KNOWLEDGE.ONOFF")')
                                v-icon(size='21' style='padding-top:2px;') mdi-power
                              v-btn.ml-2( icon  @click='onDelete(data.id)' width='24' height='24' :ripple='false'  depressed dark :title='$t("GENERAL.DELETE")')
                                v-icon(size='16' color='#6c757d') icon-remove
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addDialog' width='400' content-class='statusDialog' v-if='addDialog')
      add-dialog(@emitAddDialog='onEmitAddDialog')
    
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addDialog from "@/components/GPT_Dialog/Knowledge/addKnowledge";
import i18n from '/common/plugins/vue-i18n.js';
import KnowledgeLibraryService from "../services/KnowledgeLibraryService";
import AiBotsService from "../services/AiBotsService";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addDialog,
  },
  data() {
    return {
      // other
      loading:false,
      isDelete:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      Knowledges_list:[],
      botList:[],
      deleteId:null,

    };
  },
  async created() {
    this.loading = true;
    await this.getBotList();
    await this.getListData();
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    async getListData(){
      await KnowledgeLibraryService.list().then((response)=>{
        this.Knowledges_list = response.data;
        this.Knowledges_list.forEach(el=>{
          el.robots = el.linked_bots_and_knowledge_set.map(item=> {return item.ai_bot_id});
          el.robots_count = el.linked_bots_and_knowledge_set.length;
          el.docs_count = el.knowledge_detial_set.length;
        })
      })
    },
    async getBotList(){
      await AiBotsService.select_list().then((response)=>{
        this.botList = response.data;
      })
    },
    async openKnowledge(data){
      let edit_data = {
        "id" : data.id,
        "active" : !data.active
      }
      await KnowledgeLibraryService.edit(edit_data).then((response)=>{
        data.active = response.data.active;
      })
    },
    onDelete(id){
      this.deleteId = id;
      this.isDelete = true;
      this.message = i18n.t("GPT_KNOWLEDGE.DEL_MSG");
      this.messageDialog = true;
    },
    toDetail(id){
      if(!this.isDelete){
        this.$router.push(this.$route.path+"/"+id);
      }
    },
    getFormatTime(data){
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
        let today  = new Date(data);
        if(this.$i18n.locale == "zh_TW")
        {
            return today.toLocaleDateString("zh-TW", options); 
        }else
        {
            return today.toLocaleDateString("en-US", options);
        }                
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddDialog() {
      this.addDialog = true;
    },
    onEmitAddDialog(val,data){
      if(val){
        data.robots = data.linked_bots_and_knowledge_set.map(item=> {return item.ai_bot_id});
        data.robots_count = data.linked_bots_and_knowledge_set.length;
        data.docs_count = data.knowledge_detial_set.length;
        this.Knowledges_list.unshift(data);
      }
      this.addDialog = false;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog(val) {
      if(val && this.isDelete){
          await KnowledgeLibraryService.delete(this.deleteId).then(()=>{
            this.Knowledges_list = this.Knowledges_list.filter(el=>el.id != this.deleteId)
        })
      }
      this.isDelete = false;
      this.deleteId = null,
      this.messageDialog = false;
    },
  },
  watch:{
    "messageDialog":function(){
      if(!this.messageDialog){
        this.isDelete = false;
      }
    }
  }
};
</script>