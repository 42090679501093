<template lang='pug'>
  #GPT.main-page.GPT-color.page-noScroll.pb-0.position-relative
    //- loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='3' md='3')
        h1.my-0 {{$t('GPT_MENU.USER_MANAGEMENT')}}
    #page-inner.scroll-x.pb-0.height_100vh
      .upload_page_height.scrollbar.px-3(style='padding-top:10px;')
          v-container.pa-1.upload_page-container(max-content)
            v-card.rounded-card.pa-0.ma-0
              gptManageUsers
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
      success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
</template>

<script>
import refreshBtn from "@/components/Common/refreshBtn";
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
// import i18n from '/common/plugins/vue-i18n.js';
import gptManageUsers from "../components/GPT_Common/gptManageUsers.vue";

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    refreshBtn,
    gptManageUsers,
  },
  data() {
    return {
      // other
      loading:false,
      isDelete:false,
      // Dialog
      addDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
    };
  },
  async created() {
  },
  destroyed() {
  },
  methods: {
    // Dialog Area ----------------------------------------- [Start]
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    async onEmitMessageDialog() {
      this.messageDialog = false;
    },
    async onEmitRefresh(){
        this.loading = true;
        await this.getKnowledgeList();
        await this.getListData();
        this.loading = false;
    },
  },
  watch:{
  }
};
</script>